@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  background-color: $background-paper;
  padding: $spacing-8;

  &__row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: $spacing-4;
    flex: 1;
    flex-wrap: wrap;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
