@import "~@/styles/theme/main.scss";
.content {
  position: absolute;
  z-index: 0;
  display: flex;
  height: 150px;
  width: 150px;
  margin-bottom: $spacing-12;
  color: $primary-100;
  fill: $primary-100;
  bottom: -32%;
  right: -7.8%;

  &--invert {
    right: unset;
    left: -7.8%;
    bottom: -25%;
  }

  @include media-tablet {
    display: none;
  }
}
