@import "~@/styles/theme/main.scss";
.container {
  padding-top: $spacing-10;
  position: relative;
  padding-bottom: $spacing-28;
  width: 100%;
  background-color: $background-paper;
  display: grid;

  .column {
    width: 80%;
    margin-inline: auto;
    display: flex;
    gap: $spacing-10;
    margin-bottom: $spacing-14;

    & h4 {
      padding-left: 30%;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: $spacing-6;

      @include media-phone {
        padding-left: 0px;
      }
    }

    .item {
      padding-left: 30%;
      display: flex;
      flex-direction: column;
      gap: $spacing-2;

      &__wrapper {
        display: flex;
        flex-direction: column;
        gap: $spacing-4;

        &__label {
          width: 30%;
          display: flex;
          align-items: center;
          gap: $spacing-2;
          font-size: 12px;
          color: $text-secondary;

          &__divider {
            width: 100%;
            border-bottom: 1px solid $text-secondary;
          }
        }

        &__hours {
          font-size: 28px;
          font-weight: 700;
          color: $text-primary;
        }
      }

      @include media-phone {
        padding-left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include media-tablet {
        flex-direction: column;
      }
    }

    @include media-phone {
      flex-direction: column;
      text-align: center;
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    gap: $spacing-2;

    .icon {
      width: 32px;
      height: 32px;
      color: $text-primary;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: $primary-500;
      }
    }

    @include media-phone {
      justify-content: center;
      margin-top: 10px;
    }
  }

  .divider {
    margin-inline: auto;
    width: 80%;
    height: 2px;
    background-color: $text-secondary;
  }

  .text {
    margin-block: $spacing-4;
    margin-inline: auto;
    display: flex;
    align-items: center;
    gap: $spacing-2;
  }

  .w-full {
    width: 100%;
  }
}
