$dark-background-color: rgb(16, 16, 16);
$dark-container-color: rgb(21, 22, 24);
$dark-text-primary-color: rgb(255, 255, 255);
$dark-text-secondary-color: rgb(194, 194, 194);
$dark-text-tertiary-color: #9a9b9c;
$dark-divider-color: rgb(21, 23, 23);
$dark-box-shadow-color: rgba(255, 255, 255, 0.1);

@mixin dark-theme {
  --background-default: #{$dark-background-color};
  --background-paper: #{$dark-container-color};
  --text-primary-color: #{$dark-text-primary-color};
  --text-secondary-color: #{$dark-text-secondary-color};
  --text-tertiary-color: #{$dark-text-tertiary-color};
  --divider-color: #{$dark-divider-color};
  --box-shadow-color: #{$dark-box-shadow-color};
}
