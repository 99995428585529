$light-background-color: rgb(255, 255, 255);
$light-container-color: rgb(240, 240, 240);
$light-text-primary-color: rgb(51, 51, 51);
$light-text-secondary-color: rgb(153, 153, 153);
$light-divider-color: rgb(242, 243, 244);
$light-box-shadow-color: rgba(0, 0, 0, 0.1);

@mixin light-theme {
  --background-default: #{$light-background-color};
  --background-paper: #{$light-container-color};
  --text-primary-color: #{$light-text-primary-color};
  --text-secondary-color: #{$light-text-secondary-color};
  --divider-color: #{$light-divider-color};
  --box-shadow-color: #{$light-box-shadow-color};
}
