$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1366px;
$breakpoint-xxl: 1600px;
$border-radius: 8px;
$spacing-unit: 8px;

@mixin component-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .content {
    width: 100%;
    max-width: 1500px;
    @content;
  }
}

@mixin media-phone {
  @media (max-width: $breakpoint-sm) {
    @content;
  }
}

@mixin media-tablet {
  @media (max-width: $breakpoint-md) {
    @content;
  }
}

@mixin media-desktop {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin media-large-desktop {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin media-x-large-desktop {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}

@mixin dark-mode {
  html[data-theme="dark"] & {
    @content;
  }
}

@mixin default-shape($border-width: null, $border-radius: null) {
  border: $border-width;
  border-color: rgba(#0a0a0a, 0.2);

  @include dark-mode {
    border-color: rgba(#f4f5f7, 0.2);
  }

  @if $border-width != null {
    border-width: $border-width;
  } @else {
    border-width: 0px;
  }
  @if $border-width != null {
    border-radius: $border-radius;
  } @else {
    border-radius: 8px;
  }
}

@mixin responsive-font {
  font-size: 1rem;

  @media (min-width: $breakpoint-xl) {
    font-size: 1.5rem;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 1.25rem;
  }

  @media (max-width: $breakpoint-md) {
    font-size: 1rem;
  }

  @media (max-width: $breakpoint-sm) {
    font-size: 0.875rem;
  }
}

@mixin background-with-contrast($color) {
  $contrast-color: null;

  @if $color == $primary-50 {
    $contrast-color: $primary-text-contrast-50;
  } @else if $color == $primary-100 {
    $contrast-color: $primary-text-contrast-100;
  } @else if $color == $primary-200 {
    $contrast-color: $primary-text-contrast-200;
  } @else if $color == $primary-300 {
    $contrast-color: $primary-text-contrast-300;
  } @else if $color == $primary-400 {
    $contrast-color: $primary-text-contrast-400;
  } @else if $color == $primary-500 {
    $contrast-color: $primary-text-contrast-500;
  } @else if $color == $primary-600 {
    $contrast-color: $primary-text-contrast-600;
  } @else if $color == $primary-700 {
    $contrast-color: $primary-text-contrast-700;
  } @else if $color == $primary-800 {
    $contrast-color: $primary-text-contrast-800;
  } @else if $color == $primary-900 {
    $contrast-color: $primary-text-contrast-900;
  }

  // Check for each secondary color
  @else if $color == $secondary-50 {
    $contrast-color: $secondary-text-contrast-50;
  } @else if $color == $secondary-100 {
    $contrast-color: $secondary-text-contrast-100;
  } @else if $color == $secondary-200 {
    $contrast-color: $secondary-text-contrast-200;
  } @else if $color == $secondary-300 {
    $contrast-color: $secondary-text-contrast-300;
  } @else if $color == $secondary-400 {
    $contrast-color: $secondary-text-contrast-400;
  } @else if $color == $secondary-500 {
    $contrast-color: $secondary-text-contrast-500;
  } @else if $color == $secondary-600 {
    $contrast-color: $secondary-text-contrast-600;
  } @else if $color == $secondary-700 {
    $contrast-color: $secondary-text-contrast-700;
  } @else if $color == $secondary-800 {
    $contrast-color: $secondary-text-contrast-800;
  } @else if $color == $secondary-900 {
    $contrast-color: $secondary-text-contrast-900;
  }

  @if $contrast-color != null {
    background-color: $color !important;
    color: $contrast-color !important;
  } @else {
    @warn "No contrast color defined for #{$color}";
  }
}
