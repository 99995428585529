@import "~@/styles/theme/main.scss";
.section {
  padding-block: $spacing-10;

  .container {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    background-color: $background-default;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $spacing-4;
    text-align: start;

    @include media-x-large-desktop {
      padding: 0px;
    }

    .title {
      font-size: 36px;
      font-weight: bold;
      text-align: start;
      width: 100%;
      margin-bottom: $spacing-4;
    }

    .content__container {
      width: 100%;
      display: flex;
      flex: 2;
      flex-wrap: wrap;
      align-content: flex-start;
      gap: 1rem;
      padding: 0px 20px;

      .card {
        flex-grow: 1;
        min-width: 200px;
        text-align: center;
        border: 1px solid $primary-900;
        padding: 10px 20px;
        border-radius: $border-sm;

        @include media-tablet {
          min-width: unset;
        }

        @include media-phone {
          flex: 2;
        }

        @include dark-mode {
          border: 1px solid white;
        }

        .make {
          font-weight: 400;
        }

        .model {
          color: $primary-500;
          font-weight: bold;
        }
      }
    }

    @include media-phone {
      padding: 5px 10px;
    }

    @include media-tablet {
      padding: 0px 20px;
    }
  }
}
