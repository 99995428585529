@use "sass:color";

@import "common";
@import "light-theme";
@import "skeleton";
@import "dark-theme";

$primary-50: var(--primary-50);
$primary-100: var(--primary-100);
$primary-200: var(--primary-200);
$primary-300: var(--primary-300);
$primary-400: var(--primary-400);
$primary-500: var(--primary-500);
$primary-600: var(--primary-600);
$primary-700: var(--primary-700);
$primary-800: var(--primary-800);
$primary-900: var(--primary-900);

$secondary-50: var(--secondary-50);
$secondary-100: var(--secondary-100);
$secondary-200: var(--secondary-200);
$secondary-300: var(--secondary-300);
$secondary-400: var(--secondary-400);
$secondary-500: var(--secondary-500);
$secondary-600: var(--secondary-600);
$secondary-700: var(--secondary-700);
$secondary-800: var(--secondary-800);
$secondary-900: var(--secondary-900);

$primary-text-contrast-50: var(--primary-text-contrast-50);
$primary-text-contrast-100: var(--primary-text-contrast-100);
$primary-text-contrast-200: var(--primary-text-contrast-200);
$primary-text-contrast-300: var(--primary-text-contrast-300);
$primary-text-contrast-400: var(--primary-text-contrast-400);
$primary-text-contrast-500: var(--primary-text-contrast-500);
$primary-text-contrast-600: var(--primary-text-contrast-600);
$primary-text-contrast-700: var(--primary-text-contrast-700);
$primary-text-contrast-800: var(--primary-text-contrast-800);
$primary-text-contrast-900: var(--primary-text-contrast-900);

$secondary-text-contrast-50: var(--secondary-text-contrast-50);
$secondary-text-contrast-100: var(--secondary-text-contrast-100);
$secondary-text-contrast-200: var(--secondary-text-contrast-200);
$secondary-text-contrast-300: var(--secondary-text-contrast-300);
$secondary-text-contrast-400: var(--secondary-text-contrast-400);
$secondary-text-contrast-500: var(--secondary-text-contrast-500);
$secondary-text-contrast-600: var(--secondary-text-contrast-600);
$secondary-text-contrast-700: var(--secondary-text-contrast-700);
$secondary-text-contrast-800: var(--secondary-text-contrast-800);
$secondary-text-contrast-900: var(--secondary-text-contrast-900);

$background-default: var(--background-default);
$background-paper: var(--background-paper);

$text-primary: var(--text-primary-color);
$text-secondary: var(--text-secondary-color);

$divider-color: var(--divider-color);

$border-sm: var(--border-sm);
$border-default: var(--border-default);
$border-lg: var(--border-lg);
$border-full: var(--border-full);

$box-shadow-sm: var(--box-shadow-sm);
$box-shadow-default: var(--box-shadow-default);
$box-shadow-md: var(--box-shadow-md);
$box-shadow-lg: var(--box-shadow-lg);
$box-shadow-xl: var(--box-shadow-xl);
$box-shadow-2xl: var(--box-shadow-2xl);
$box-shadow-3xl: var(--box-shadow-3xl);
$box-shadow-inner: var(--box-shadow-inner);
$box-shadow-none: var(--box-shadow-none);

$font-sans: var(--font-sans);
$font-serif: var(--font-serif);

$spacing-px: var(--spacing-px);
$spacing-0: var(--spacing-0);
$spacing-0-5: var(--spacing-0-5);
$spacing-1: var(--spacing-1);
$spacing-1-5: var(--spacing-1-5);
$spacing-2: var(--spacing-2);
$spacing-2-5: var(--spacing-2-5);
$spacing-3: var(--spacing-3);
$spacing-3-5: var(--spacing-3-5);
$spacing-4: var(--spacing-4);
$spacing-4-5: var(--spacing-4-5);
$spacing-5: var(--spacing-5);
$spacing-6: var(--spacing-6);
$spacing-6: var(--spacing-6);
$spacing-7: var(--spacing-7);
$spacing-8: var(--spacing-8);
$spacing-9: var(--spacing-9);
$spacing-10: var(--spacing-10);
$spacing-11: var(--spacing-11);
$spacing-12: var(--spacing-12);
$spacing-14: var(--spacing-14);
$spacing-16: var(--spacing-16);
$spacing-20: var(--spacing-20);
$spacing-24: var(--spacing-24);
$spacing-28: var(--spacing-28);
$spacing-32: var(--spacing-32);
$spacing-36: var(--spacing-36);
$spacing-40: var(--spacing-40);
$spacing-44: var(--spacing-44);
$spacing-48: var(--spacing-48);
$spacing-52: var(--spacing-52);
$spacing-56: var(--spacing-56);
$spacing-60: var(--spacing-60);
$spacing-64: var(--spacing-64);
$spacing-72: var(--spacing-72);
$spacing-80: var(--spacing-80);
$spacing-96: var(--spacing-96);

.light {
  @include light-theme;
  @include responsive-font;

  background-color: $background-default;
  color: $text-primary;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-sans) !important;
  }

  *:not(h1, h2, h3, h4, h5, h6) {
    font-family: var(--font-serif) !important;
  }
}

.dark {
  @include dark-theme;
  @include responsive-font;

  background-color: $background-default;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-sans) !important;
  }

  *:not(h1, h2, h3, h4, h5, h6) {
    font-family: var(--font-serif) !important;
  }
}
