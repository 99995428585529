@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  background-color: $background-default;
  padding: 34px 64px;
  padding-block: $spacing-10;

  .container__content {
    display: flex;

    .content__wrapper {
      width: 80%;
      margin: auto auto;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: $spacing-4;
      }
    }
  }
}
