@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 1500px;
    margin-inline: auto;
    padding-inline: $spacing-2;

    @include media-tablet {
      padding-block: $spacing-20;
      padding-inline: $spacing-10;
    }
  }
}
