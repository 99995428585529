@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  position: relative;
  background-color: $background-default;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-inline: auto;
  padding: $spacing-4;
  padding-block: $spacing-10;

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1500px;

    &--invert {
      flex-direction: row-reverse;
    }

    @include media-tablet {
      flex-direction: column;
      margin-inline: auto;
    }
  }

  .text-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: $spacing-4;

    & h2 {
      color: $primary-600;
      font-size: 36px;
      font-weight: 700;
    }

    @include media-tablet {
      width: 100%;
    }
  }

  .image-container {
    position: relative;
    margin-bottom: 32px;

    .image {
      position: sticky;
      z-index: 10;
      width: 100%;
      max-width: 450px;
      max-height: auto;
      object-fit: cover;
      object-position: center;
      @include default-shape;
      box-shadow: $box-shadow-2xl;
      margin-top: $spacing-10;
    }
  }
}
