@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  background-color: $background-default;
  padding: 34px 64px;
  padding-block: $spacing-10;

  @include media-x-large-desktop {
    @include component-container;
    padding-inline: 300px;
  }

  @include media-large-desktop {
    @include component-container;
    padding-inline: 270px;
  }

  .wrapper {
    display: flex;

    @include media-tablet {
      flex-direction: column;
    }
  }

  .wrapper__invert {
    display: flex;
    flex-direction: row-reverse;

    @include media-tablet {
      flex-direction: column;
    }
  }

  .container__image {
    width: 50%;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-sm;
    }

    @include media-tablet {
      width: 100%;
      align-items: center;
    }
  }

  .container__content {
    width: 50%;
    display: flex;

    .content__wrapper {
      width: 80%;
      margin: auto auto;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: $spacing-4;
      }
    }

    @include media-tablet {
      width: 100%;
    }
  }
}
