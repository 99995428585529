%skeleton-placeholder {
  position: relative;
  overflow: hidden !important;
  background-color: var(--container-color);

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden !important;
    transform: translateX(-100%);
    content: "";
    background: linear-gradient(
      0.25turn,
      transparent,
      var(--background-color),
      transparent
    );
    pointer-events: none;
    opacity: 0.8;
    animation: skeleton-animation 2s infinite;
  }

  @keyframes skeleton-animation {
    0% {
      transform: translateX(-100%);
    }
    30% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

%skeleton-placeholder-secondary {
  background-color: var(--background-color);

  &::after {
    background: linear-gradient(
      0.25turn,
      transparent,
      var(--container-color),
      transparent
    );
  }
}

@mixin skeleton(
  $width: null,
  $height: null,
  $animate: true,
  $secondary: false
) {
  @extend %skeleton-placeholder;

  @if $width != null {
    width: $width;
  } @else {
    width: 100%;
  }
  @if $height != null {
    height: $height;
  } @else {
    height: 100%;
  }
  @if $animate != true {
    &::before {
      display: none;
    }
  }

  border-radius: var(--border-sm);

  @if $secondary == true {
    @extend %skeleton-placeholder-secondary;
  }
}
