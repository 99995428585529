@import "~@/styles/theme/main.scss";
.section {
  padding-block: $spacing-10;

  .container {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    background-color: $background-default;

    .title {
      font-size: 36px;
      font-weight: bold;
      text-align: start;
      width: 100%;
      margin-bottom: $spacing-4;

      span {
        font-size: 36px;
        font-weight: bold;
        text-align: start;
        width: 100%;
        margin-bottom: $spacing-4;
        color: $primary-500;
        cursor: pointer;
      }
    }

    .instagram__gallery {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 10px;

      .instagram__post {
        width: 100%;
        border-radius: $border-sm;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        &:hover .post__content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
        }

        img {
          width: 100%;
          height: auto;
        }

        .post__content {
          display: none;
          background-color: #00000095;
          position: absolute;
          inset: 0;
          z-index: 10;
          color: white;
          padding: 10px;
          padding-bottom: 0px;
          cursor: pointer;
          animation: bottomToTop 0.8s forwards;

          .content__icons {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .icon {
              color: white;
            }

            .icon:hover {
              color: $primary-500;
            }
          }

          .post__description {
            font-size: 12px;
            font-weight: bold;
            width: 100%;
            height: 40%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          @keyframes bottomToTop {
            from {
              bottom: 1000px;
              opacity: 0;
            }

            to {
              bottom: 0;
              opacity: 1;
            }
          }
        }
      }

      .big {
        grid-column: span 2;
        grid-row: span 2;

        p {
          font-size: 24px !important;
        }
      }
    }

    @include media-x-large-desktop {
      padding: 0px;
    }

    @include media-phone {
      padding: 5px 10px;
    }

    @include media-tablet {
      padding: 0px 20px;
    }
  }
}
