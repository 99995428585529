@import "~@/styles/theme/main.scss";
.section {
  padding-block: $spacing-10;

  .container {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    background-color: $background-default;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $spacing-4;
    text-align: start;

    .title {
      font-size: 36px;
      font-weight: bold;
      text-align: start;
      width: 100%;
      margin-bottom: $spacing-4;
    }

    .content__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 2;
      flex-wrap: wrap;
      gap: 1rem;
      padding: 0px 20px;

      .card__wrapper {
        padding: 20px;
        width: 200px;
        height: 200px;
        border: 1px solid transparent;
        border-radius: $border-sm;
        transition: border-color 0.3s ease;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover {
          border: 1px solid $primary-900;

          @include dark-mode {
            border: 1px solid $primary-200;
          }
        }

        .card {
          width: 140px;
          height: 140px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .icon {
            width: 150px;
            height: 80px;
            // fill: "#FFFFFFF"
          }
        }

        @include media-phone {
          padding: 5px;
          width: 170px;
          height: 170px;
        }
      }

      @include media-phone {
        gap: 0.1rem;
      }
    }

    .st0 {
      fill: #2f333e;

      @include dark-mode {
        fill: $primary-100;
      }
    }

    @include media-x-large-desktop {
      padding: 0px;
    }

    @include media-phone {
      padding: 5px 10px;
    }

    @include media-tablet {
      padding: 0px 20px;
    }
  }
}
