@import "~@/styles/theme/main.scss";
.section {
  padding-block: $spacing-10;

  .container {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    background-color: $background-default;

    .wrapper-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .title {
        font-size: 36px;
        font-weight: bold;
        width: 100%;
      }

      .description {
        font-size: 12px;
        width: 100%;
        margin-bottom: $spacing-4;
      }
    }

    .content-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .column {
        flex: 1;
        text-align: center;

        h2 {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 15px;
        }

        .list {
          width: 100%;

          .list__item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .list__item__label {
              font-size: 18px;
              font-weight: 400;
              width: 200px;
              text-align: start;

              @include media-tablet {
                font-size: 15px;
                width: 150px;
              }
            }

            .list__item__value {
              font-size: 18px;
              font-weight: bold;
              width: 200px;

              @include media-tablet {
                font-size: 15px;
                width: 150px;
              }
            }
          }
        }
      }

      .divider {
        width: 2px;
        height: 250px;
        background-color: black;
        position: absolute;
        top: 10px;

        @include media-tablet {
          top: 50%;
          height: 1px;
          width: 250px;
        }
      }

      @include media-tablet {
        flex-direction: column;
        gap: 3rem;
      }
    }

    @include media-x-large-desktop {
      padding: 0px;
    }

    @include media-phone {
      padding: 5px 10px;
    }

    @include media-tablet {
      padding: 0px 20px;
    }
  }
}
