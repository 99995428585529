@import "~@/styles/theme/main.scss";
.card {
  @include default-shape;

  position: relative;
  display: flex;
  background-color: $background-paper;
  flex-direction: column;
  overflow: hidden;
  height: max-content;
  border: 1px solid #ccc;
  max-height: 430px;

  &--adjust-height {
    min-height: 450px;
  }

  .image {
    position: relative;
    margin: 0;
  }

  .content {
    flex: 1;
    padding: $spacing-4;
    gap: $spacing-1;
    display: flex;
    flex-direction: column;

    & h3 {
      font-size: 22px;
      font-weight: 600;
      line-height: 1rem;

      & .highlight {
        line-height: 1.75rem;
        color: $primary-600;
        font-weight: 700;
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: end;

      .description {
        font-size: 18px;
        color: $text-secondary;
      }

      & h4 {
        font-size: 20px;
        font-weight: 600;

        & .price {
          color: $primary-600;

          @include dark-mode {
            color: $primary-200;
          }
        }
      }
    }

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $spacing-3;
      margin-top: $spacing-3;

      & p {
        width: max-content;
        font-size: 13px;
        color: $text-secondary;
        font-weight: 700;
      }
    }
  }
}
