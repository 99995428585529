@import "~@/styles/theme/main.scss";
.container {
  background-color: $primary-500;
  width: 100%;
  z-index: 40;
  border-bottom: 1px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    display: flex;
    max-width: 1500px;
    margin-inline: auto;
    height: $spacing-10;
    padding-inline: $spacing-8;
    text-align: center;
    align-items: center;
    justify-content: space-around;

    @include media-tablet {
      justify-content: space-around;
      padding-inline: $spacing-6;
    }

    .link {
      flex: none;
      display: block;
      align-items: end;
      font-weight: 500;
      font-size: 14px;

      & a {
        font-size: 14px;
      }

      .icon {
        width: $spacing-5;
        height: $spacing-5;
        display: inline;
        margin-right: $spacing-1;
        margin-bottom: 3px;
      }
    }

    .hedden {
      @include media-tablet {
        display: none;
      }
    }
  }
}
